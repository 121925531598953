<template>
  <div class="card rounded-3 w-md-550px">
    <div class="card-body p-10 p-lg-20">
      <form
        class="form w-100"
        novalidate="novalidate"
        id="kt_sign_in_form"
        @submit.prevent="handleLogin"
      >
        <div class="text-center mb-11">
          <h1 class="text-dark fw-bolder mb-3">BIENVENIDO A VENTO</h1>
          <div class="text-gray-500 fw-semibold fs-6">
            PORTAL PARA PROVEEDORES
          </div>
        </div>
        <div class="separator separator-content my-14">
          <span class="text-gray-500 fw-semibold fs-7"
            >Favor de ingresar su correo y contraseña</span
          >
        </div>
        <div class="fv-row mb-8">
          <input
            type="text"
            placeholder="Correo Electrónico"
            name="email"
            autocomplete="off"
            class="form-control bg-transparent"
            v-model="loginForm.username"
          />
        </div>
        <div class="fv-row mb-3">
          <input
            type="password"
            placeholder="Contraseña"
            name="password"
            autocomplete="off"
            class="form-control bg-transparent"
            v-model="loginForm.password"
          />
        </div>
        <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
          <div
            class="alert alert-danger d-flex align-items-center p-2 w-100"
            v-if="errorText.length > 0"
          >
            <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
            <div class="d-flex flex-column">
              <span>{{ errorText }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div></div>
          <a
            href="../../demo1/dist/authentication/layouts/creative/reset-password.html"
            class="link-primary"
            >¿Olvido su contraseña?</a
          >
        </div>

        <div class="d-grid mb-10">
          <button type="submit" id="kt_sign_in_submit" class="btn btn-primary">
            <span class="indicator-label">INGRESAR</span>
            <span class="indicator-progress"
              >Ingresando al sitio...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span
            ></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import useAuth from "../composables/useAuth";
import { useRouter } from "vue-router";

export default {
  setup() {
    const { loginUser } = useAuth();
    const errorText = ref("");
    const router = useRouter();

    const loginForm = ref({
      username: "",
      password: "",
    });

    const handleLogin = async () => {
      const btnLogin = document.querySelector("#kt_sign_in_submit");
      btnLogin.setAttribute("data-kt-indicator", "on");
      btnLogin.disabled = true;

      const data = await loginUser(loginForm.value);

      btnLogin.setAttribute("data-kt-indicator", "off");
      btnLogin.disabled = false;

      if (data.ok) {
        router.push({ name: "facturas" });
        errorText.value = "";
      } else {
        errorText.value = data.message;
      }
    };

    watchEffect(() => errorText.value);

    return { errorText, loginForm, handleLogin };
  },
};
</script>

<style lang="scss" scoped>
</style>